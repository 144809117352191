header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	box-shadow: 0 0 10px rgba(black, 0.1);
	background-color: map-get($color, white);
}
.header__wrapper {
	padding: 15px 40px;
	box-shadow: 0px 0px 12px rgba(map-get($color, black), 0.2);
	@media (max-width: 1199.98px) {
		padding: 10px 25px;
	}
	@media (max-width: 767.98px) {
		padding: 5px 10px;
	}
}
.header__logo {
	img {
		height: 60px;
		@media (max-width: 1199.98px) {
			height: 55px;
		}
		@media (max-width: 767.98px) {
			height: 50px;
		}
	}
}
.header__contact {
	.btn--primary {
		border-radius: 30px;
		text-decoration: none;
		font-weight: 500;
		font-size: 2.2rem;
		padding: 1.5rem 4rem;
		span.fal {
			margin-left: 10px;
		}
		@media (max-width: 767.98px) {
			padding: 1rem 3rem;
			font-size: 14px;
			span.fal {
				display: none;
			}
		}
	}
	@media (max-width: 1024.98px) {
		margin-left: auto;
	}
}
.header__toggle {
	margin-left: 30px;
	-ms-flex: 0 0 30px;
	flex: 0 0 30px;
	max-width: 30px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	.btn--toggle {
		height: 30px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.toggle__bar {
			display: block;
			width: 20px;
			height: 2px;
			margin: 2px 0;
			background-color: map-get($color, black);
			transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			transform-origin: 50% 50%;
			border-radius: 2px;
		}
		&.is-active {
			.toggle__bar:nth-child(1) {
				transform: rotate(-45deg) translate(-2px, 2px);
				width: 14px;
			}
			.toggle__bar:nth-child(2) {
				transform: rotate(45deg) translate(-2px, -2px);
				width: 14px;
			}
		}
	}
	@media (max-width: 767.98px) {
		margin-left: 10px;
	}
}
.header__siteMenu--hidden {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	width: 350px;
	max-width: 100%;
	height: 100%;
	background-color: map-get($color, main);
	padding: 60px 0;
	transform: translateX(100%);
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	&.is-showed {
		transform: translateX(0%);
		opacity: 1;
		visibility: visible;
		.header__siteMenu--close {
			opacity: 1;
			visibility: visible;
		}
	}
	@media (max-width: 1199.98px) {
		width: 320px;
	}
	@media (max-width: 767.98px) {
		width: 280px;
	}
}
.header__siteMenu--close {
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	right: 100%;
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: map-get($color, black);
	cursor: pointer;
	span {
		color: map-get($color, white);
	}
}
.header__siteMenu__wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	-webkit-overflow-scrolling: touch;
}
.header__nav {
	margin-left: auto;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		@media (max-width: 1024.98px) {
			flex-direction: column;
		}
	}
	a {
		font-size: 1.7rem;
		font-weight: 500;
		text-decoration: none;
		color: map-get($color, black);
		display: inline-block;
		transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		&:hover {
			color: map-get($color, main);
		}
	}
	li {
		padding-right: 45px;
		&.current-menu-item {
			a {
				@media (min-width: 1025px) {
					color: map-get($color, main);
					font-weight: 500;
					border-bottom: 1px solid map-get($color, main);
				}
				@media (max-width: 1024.98px) {
					border-bottom: 1px solid map-get($color, black);
				}
			}
		}
	}
	@media (max-width: 1024.98px) {
		padding: 0 45px;
		margin-left: 0;
		li {
			padding: 12px 0;
		}
		a {
			font-size: 2.5rem;
			text-transform: uppercase;
		}
	}
}
