.our-works {
	.pd-70 {
		&:nth-child(even) {
			background-color: #fafafa;
		}
		@media (min-width: 1025px) {
			&:nth-child(odd) {
				.our-works-article {
					flex-direction: row;
				}
			}
			&:nth-child(even) {
				.our-works-article {
					flex-direction: row-reverse;
				}
			}
		}
	}
	.our-works-title {
		.section-title {
			font-size: calc(3rem + (4000vw / 1920));
			color: #ee4266;
		}
	}
	@media (max-width: 1024.98px) {
		.our-works-title {
			.section-title {
				text-align: center;
			}
		}
	}
}

.our-works-article {
	margin-bottom: 45px;
	.block-text {
		h3 {
			font-size: 3rem;
			color: #333333;
		}
		ul {
			list-style-type: none;
			padding-left: 0;
		}
		img {
			display: inline-block;
			margin-top: 25px;
			margin-bottom: 30px;
		}
		ul,
		p {
			margin-bottom: 15px;
			font-size: 2.2rem;
			font-weight: 500;
			color: #5b5b5b;
		}
		ul {
			li {
				position: relative;
				padding-left: 20px;
				&:before {
					content: '';
					position: absolute;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					left: 0;
					top: 1.2rem;
					background-color: #5b5b5b;
				}
				+ li {
					margin-top: 10px;
				}
			}
		}
		a {
			color: map-get($color, black);
			text-decoration: none;
			display: inline-block;
			margin-top: 10px;
			border-radius: 5px;
			margin-top: 15px;
			font-size: 1.8rem;
			text-decoration: underline;
			transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			span.fal {
				font-size: 16px;
				margin-left: 10px;
				transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			}
			&:hover {
				color: map-get($color, main);
				span.fal {
					margin-left: 20px;
				}
			}
		}
	}
	.block-img {
		@media (max-width: 1024.98px) {
			margin-bottom: 30px;
			img {
				max-height: 375px;
			}
		}
	}
	@media (max-width: 1024.98px) {
		.block-text {
			padding: 20px;
			background-color: rgba(map-get($color, main), 0.5);
		}
	}
	@media (min-width: 1025px) {
		margin-bottom: 0;
		.block-text {
			padding: 0 50px;
		}
		&:nth-child(even) {
			.col-lg-4 {
				order: 3;
			}
			.col-lg-1 {
				order: 2;
			}
			.col-lg-7 {
				order: 1;
			}
		}
	}
}
