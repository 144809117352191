.what-we-do {
	.block-title {
		p {
			font-size: 2.2rem;
		}
	}
}
.what-we-do-1 {
	.section-title {
		color: #f38a67;
	}
	.grid-container {
		display: flex;
		flex-wrap: wrap;
		.item {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			-ms-flex: 0 0 calc(100% / 8);
			flex: 0 0 calc(100% / 8);
			max-width: calc(100% / 8);
			.text {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				background-color: rgba(map-get($color, black), 0.8);
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
				padding: 15px;
				p {
					font-size: 2.2rem;
					font-weight: 500;
					color: map-get($color, white);
				}
			}
			.img {
				width: 100%;
				height: 100%;
				transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
				img {
					object-fit: cover;
					transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
					width: 100%;
					height: 100%;
				}
			}
			@media (min-width: 1025px) {
				&:hover {
					.img {
						background-color: black;
						img {
							opacity: 0.5;
						}
					}
				}
			}
			&:nth-child(13),
			&:nth-child(14),
			&:nth-child(15) {
				-ms-flex: 0 0 calc(100% / 4);
				flex: 0 0 calc(100% / 4);
				max-width: calc(100% / 4);
			}
		}
		@media (max-width: 767.98px) {
			margin: 0 -5px;
			.item {
				-ms-flex: 0 0 33.3333%;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				&:nth-child(13),
				&:nth-child(14),
				&:nth-child(15) {
					-ms-flex: 0 0 100%;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
}
.what-we-do-2 {
	.section-title {
		color: map-get($color, main);
	}
	.highlight-project {
		display: block;
		.img {
			@include img(200/300);
		}
	}
	.highlight-project-wrapper {
		.col-lg-3 {
			margin-bottom: 35px;
			@media (min-width: 1025px) {
				&:nth-child(2) {
					padding-top: 60px;
				}
				&:nth-child(4) {
					padding-top: 60px;
				}
			}
		}
	}
	.what-we-do-2-item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;
		a {
			display: block;
			width: 100%;
			height: 100%;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&:nth-child(1),
		&:nth-child(3) {
			-ms-flex: 0 0 30%;
			flex: 0 0 30%;
			max-width: 30%;
		}
		&:nth-child(2) {
			-ms-flex: 0 0 40%;
			flex: 0 0 40%;
			max-width: 40%;
		}
		@media (max-width: 767.98px) {
			&:nth-child(1),
			&:nth-child(3) {
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				order: 2;
				margin-top: 20px;
			}
			&:nth-child(2) {
				order: 1;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
	.pd-70:nth-child(2) {
		background-color: #fafafa;
	}
}
.what-we-do-3 {
	.section-title.big-title {
		color: map-get($color, main);
	}
	.section-title.sub-title {
		color: #77cbd2;
	}
	.col-6.col-lg-3 {
		margin-bottom: 35px;
	}
}
.what-we-do-4 {
	.section-title.sub-title {
		color: #f38a67;
	}
	.block-title {
		p {
			color: #5b5b5b;
			font-size: 1.8rem;
			max-width: 500px;
		}
	}
	.celeb-img {
		&:not(.big-img) {
			@include img(190/230);
		}
		&.big-img {
			margin-top: 20px;
			@include img(545/232);
		}
	}
}
.what-we-do-5 {
	.section-title.sub-title {
		color: #253083;
	}
	.col-6.col-lg-3 {
		margin-bottom: 35px;
	}
}
.what-we-do-6 {
	.section-title.sub-title {
		color: #3bc8b4;
	}
	.col-6.col-lg-4 {
		margin-bottom: 35px;
	}
}
.what-we-do-7 {
	.section-title.sub-title {
		color: #f95c0e;
		font-family: 'Circular Std', sans-serif;
	}
	.col-6.col-lg-4 {
		margin-bottom: 35px;
	}
	.block-title {
		display: flex;
		align-items: center;
		.section-img {
			margin-right: 30px;
			-ms-flex: 0 0 120px;
			flex: 0 0 120px;
			max-width: 120px;
		}
		.section-title {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}
.project-item {
	height: 100%;
	.project-img {
		@include img(266/162);
		box-shadow: 0 0 5px rgba(black, 0.25);
		border-radius: 5px;
		overflow: hidden;
	}
	.project-link {
		height: 100%;
		text-decoration: none;
		display: flex;
		flex-direction: column;
	}
	.project-info {
		padding-top: 2rem;
		color: #333333;
		height: 100%;
		display: flex;
		flex-direction: column;
		font-size: 1.8rem;
		h3 {
			font-size: 2rem;
			margin-bottom: 1rem;
			text-transform: capitalize;
		}
		p {
			font-size: 1.8rem;
			margin-bottom: auto;
		}
		h4 {
			margin-top: 1rem;
		}
		i {
			margin-top: 1rem;
			display: block;
			font-size: 1.6rem;
		}
	}
}
.fancybox-is-open .fancybox-bg {
	opacity: 0.35;
}
.fancybox-content {
	&.celeb-dialog {
		max-width: 600px;
		padding: 20px;
		border-radius: 10px;
		max-height: 100%;
		overflow-y: auto;
		.fancybox-close-small {
			padding: 5px;
			width: 35px;
			height: 35px;
			border-radius: 5px;
			transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			&:hover {
				background: rgba(0, 0, 0, 0.1);
			}
		}
		.celeb-dialog-wrapper {
			display: flex;
			margin: 0 -10px;
			.dialog-img {
				-ms-flex: 0 0 40%;
				flex: 0 0 40%;
				max-width: 40%;
				padding: 0 10px;
				.img-wrapper {
					img {
						width: 100%;
					}
				}
			}
			.dialog-content {
				-ms-flex: 0 0 60%;
				flex: 0 0 60%;
				max-width: 60%;
				padding: 0 10px;
				color: map-get($color, black);
				h4 {
					font-size: 2.2rem;
				}
				p,
				ul {
					font-size: 1.6rem;
					margin-bottom: 7px;
				}
			}
		}
		@media (max-width: 1024.98px) {
			max-width: 450px;
			.celeb-dialog-wrapper {
				flex-wrap: wrap;
				.dialog-img,
				.dialog-content {
					-ms-flex: 0 0 100%;
					flex: 0 0 100%;
					max-width: 100%;
				}
				.dialog-img {
					text-align: center;
					.img-wrapper {
						img {
							max-height: 200px;
							width: auto;
						}
					}
				}
				.dialog-content {
					margin-top: 15px;
				}
			}
		}
	}
}
