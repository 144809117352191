@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Black"), local("BigShouldersDisplay-Black"), url("../fonts/BigShouldersDisplay-Black.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display ExtraBold"), local("BigShouldersDisplay-ExtraBold"), url("../fonts/BigShouldersDisplay-ExtraBold.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Bold"), local("BigShouldersDisplay-Bold"), url("../fonts/BigShouldersDisplay-Bold.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Medium"), local("BigShouldersDisplay-Medium"), url("../fonts/BigShouldersDisplay-Medium.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Light"), local("BigShouldersDisplay-Light"), url("../fonts/BigShouldersDisplay-Light.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Regular"), local("BigShouldersDisplay-Regular"), url("../fonts/BigShouldersDisplay-Regular.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display SemiBold"), local("BigShouldersDisplay-SemiBold"), url("../fonts/BigShouldersDisplay-SemiBold.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Big Shoulders Display';
  src: local("Big Shoulders Display Thin"), local("BigShouldersDisplay-Thin"), url("../fonts/BigShouldersDisplay-Thin.woff2") format("woff2"), url("../fonts/BigShouldersDisplay-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Black"), local("CircularStd-Black"), url("../fonts/CircularStd-Black.woff2") format("woff2"), url("../fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Black Italic"), local("CircularStd-BlackItalic"), url("../fonts/CircularStd-BlackItalic.woff2") format("woff2"), url("../fonts/CircularStd-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Bold Italic"), local("CircularStd-BoldItalic"), url("../fonts/CircularStd-BoldItalic.woff2") format("woff2"), url("../fonts/CircularStd-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Bold"), local("CircularStd-Bold"), url("../fonts/CircularStd-Bold.woff2") format("woff2"), url("../fonts/CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std Book';
  src: local("Circular Std Book"), local("CircularStd-Book"), url("../fonts/CircularStd-Book.woff2") format("woff2"), url("../fonts/CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std Book';
  src: local("Circular Std Book Italic"), local("CircularStd-BookItalic"), url("../fonts/CircularStd-BookItalic.woff2") format("woff2"), url("../fonts/CircularStd-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Medium"), local("CircularStd-Medium"), url("../fonts/CircularStd-Medium.woff2") format("woff2"), url("../fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Circular Std';
  src: local("Circular Std Medium Italic"), local("CircularStd-MediumItalic"), url("../fonts/CircularStd-MediumItalic.woff2") format("woff2"), url("../fonts/CircularStd-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque Black"), local("DarkerGrotesque-Black"), url("../fonts/DarkerGrotesque-Black.woff2") format("woff2"), url("../fonts/DarkerGrotesque-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque Bold"), local("DarkerGrotesque-Bold"), url("../fonts/DarkerGrotesque-Bold.woff2") format("woff2"), url("../fonts/DarkerGrotesque-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque ExtraBold"), local("DarkerGrotesque-ExtraBold"), url("../fonts/DarkerGrotesque-ExtraBold.woff2") format("woff2"), url("../fonts/DarkerGrotesque-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque Light"), local("DarkerGrotesque-Light"), url("../fonts/DarkerGrotesque-Light.woff2") format("woff2"), url("../fonts/DarkerGrotesque-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque Medium"), local("DarkerGrotesque-Medium"), url("../fonts/DarkerGrotesque-Medium.woff2") format("woff2"), url("../fonts/DarkerGrotesque-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque Regular"), local("DarkerGrotesque-Regular"), url("../fonts/DarkerGrotesque-Regular.woff2") format("woff2"), url("../fonts/DarkerGrotesque-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Darker Grotesque';
  src: local("Darker Grotesque SemiBold"), local("DarkerGrotesque-SemiBold"), url("../fonts/DarkerGrotesque-SemiBold.woff2") format("woff2"), url("../fonts/DarkerGrotesque-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Drotesk No5';
  src: local("Drotesk No5 Medium"), local("DroteskNo5-Medium"), url("../fonts/DroteskNo5-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Drotesk No5';
  src: local("Drotesk No5 Regular"), local("DroteskNo5-Regular"), url("../fonts/DroteskNo5-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"); }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

.fa,
.fad,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-external-link-alt:before {
  content: '\f35d'; }

.fa-chevron-up:before {
  content: '\f077'; }

.fa-chevron-left:before {
  content: '\f053'; }

.fa-chevron-right:before {
  content: '\f054'; }

.fa-bars:before {
  content: '\f0c9'; }

.fa-chevron-down:before {
  content: '\f078'; }

.fa-home-lg-alt:before {
  content: '\f80c'; }

.fa-shopping-cart:before {
  content: '\f07a'; }

.fa-search:before {
  content: '\f002'; }

.fa-calendar-alt:before {
  content: '\f073'; }

.fa-google::before {
  content: '\f1a0'; }

.fa-facebook-f::before {
  content: '\f39e'; }

.fa-facebook-square::before {
  content: '\f082'; }

.fa-twitter::before {
  content: '\f099'; }

.fa-youtube:before {
  content: '\f167'; }

.fa-instagram::before {
  content: '\f16d'; }

.fa-tripadvisor::before {
  content: '\f262'; }

.fa-map-marker-alt:before {
  content: '\f3c5'; }

.fa-times:before {
  content: '\f00d'; }

.fa-ellipsis-h:before {
  content: '\f141'; }

.fa-check:before {
  content: '\f00c'; }

.fa-redo-alt:before {
  content: '\f2f9'; }

.fa-star:before {
  content: '\f005'; }

.fa-thumbs-up::before {
  content: '\f164'; }

.fa-plus-square:before {
  content: '\f0fe'; }

.fa-pinterest-p::before {
  content: '\f231'; }

.fa-linkedin-in::before {
  content: '\f0e1'; }

.fa-edit:before {
  content: '\f044'; }

.fa-gift:before {
  content: '\f06b'; }

.fa-comments-alt:before {
  content: '\f4b6'; }

.fa-filter:before {
  content: '\f0b0'; }

.fa-pig:before {
  content: '\f706'; }

.fa-comment-alt-edit:before {
  content: '\f4a4'; }

.fa-eye:before {
  content: '\f06e'; }

.fa-gem:before {
  content: '\f3a5'; }

.fa-bullseye:before {
  content: '\f140'; }

.fa-exclamation-circle:before {
  content: '\f06a'; }

.fa-paper-plane:before {
  content: '\f1d8'; }

@keyframes UpdownMoving {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(10px); } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  height: auto; }

body {
  margin: 0;
  font-family: 'Circular Std', 'Drotesk No5', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5B5B5B;
  background-color: #fff; }

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role='button'] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container,
.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 1025px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1160px; } }

@media (min-width: 1440px) {
  .container {
    max-width: 1360px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-2xl-1,
.col-2xl-2,
.col-2xl-3,
.col-2xl-4,
.col-2xl-5,
.col-2xl-6,
.col-2xl-7,
.col-2xl-8,
.col-2xl-9,
.col-2xl-10,
.col-2xl-11,
.col-2xl-12,
.col-2xl,
.col-2xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1440px) {
  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-2xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-2xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-2xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-2xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-2xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-2xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-2xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-2xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-2xl-0 {
    margin-left: 0; }
  .offset-2xl-1 {
    margin-left: 8.333333%; }
  .offset-2xl-2 {
    margin-left: 16.666667%; }
  .offset-2xl-3 {
    margin-left: 25%; }
  .offset-2xl-4 {
    margin-left: 33.333333%; }
  .offset-2xl-5 {
    margin-left: 41.666667%; }
  .offset-2xl-6 {
    margin-left: 50%; }
  .offset-2xl-7 {
    margin-left: 58.333333%; }
  .offset-2xl-8 {
    margin-left: 66.666667%; }
  .offset-2xl-9 {
    margin-left: 75%; }
  .offset-2xl-10 {
    margin-left: 83.333333%; }
  .offset-2xl-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1600px) {
  .col-3xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-3xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-3xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-3xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-3xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-3xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-3xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-3xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-3xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-3xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-3xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-3xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-3xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-3xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-3xl-0 {
    margin-left: 0; }
  .offset-3xl-1 {
    margin-left: 8.333333%; }
  .offset-3xl-2 {
    margin-left: 16.666667%; }
  .offset-3xl-3 {
    margin-left: 25%; }
  .offset-3xl-4 {
    margin-left: 33.333333%; }
  .offset-3xl-5 {
    margin-left: 41.666667%; }
  .offset-3xl-6 {
    margin-left: 50%; }
  .offset-3xl-7 {
    margin-left: 58.333333%; }
  .offset-3xl-8 {
    margin-left: 66.666667%; }
  .offset-3xl-9 {
    margin-left: 75%; }
  .offset-3xl-10 {
    margin-left: 83.333333%; }
  .offset-3xl-11 {
    margin-left: 91.666667%; } }

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms; }
  .blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0); }

.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

.pt-70 {
  padding-top: 100px; }
  @media (max-width: 1199.98px) {
    .pt-70 {
      padding-top: 70px; } }
  @media (max-width: 767.98px) {
    .pt-70 {
      padding-top: 40px; } }

.pb-70 {
  padding-bottom: 100px; }
  @media (max-width: 1199.98px) {
    .pb-70 {
      padding-bottom: 70px; } }
  @media (max-width: 767.98px) {
    .pb-70 {
      padding-bottom: 40px; } }

.pd-70 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 1199.98px) {
    .pd-70 {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media (max-width: 767.98px) {
    .pd-70 {
      padding-top: 40px;
      padding-bottom: 40px; } }

@media (min-width: 1025px) {
  .mobile {
    display: none !important; } }

@media (max-width: 1024.98px) {
  .desktop {
    display: none !important; } }

.d-none {
  display: none; }

.ov-h {
  overflow: hidden; }

.jc-c {
  justify-content: center; }

.jc-sb {
  justify-content: space-between; }

.jc-fe {
  justify-content: flex-end; }

.ai-c {
  align-items: center; }

.as-fs {
  align-self: flex-start; }

.ta-c {
  text-align: center; }

.ta-r {
  text-align: right; }

.ta-l {
  text-align: left; }

.ta-j {
  text-align: justify; }

.tal-c {
  text-align-last: center; }

.tt-u {
  text-transform: uppercase; }

.fs-i {
  font-style: italic; }

.form-group {
  display: block;
  width: 100%;
  margin-bottom: 20px; }

.form-control {
  width: 100%;
  display: block;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 16px;
  height: 50px; }
  .form-control:active, .form-control:focus {
    outline: none;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); }

.form-control--textarea {
  height: 140px; }

.form-group__label, .contact-1 .form-group a {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 2.2rem;
  color: #2c3241; }

.wpcf7-not-valid-tip {
  color: red;
  margin-top: 5px;
  text-align: right;
  font-weight: 500;
  display: block; }
  .wpcf7-not-valid-tip:before {
    content: '\f06a';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    margin-right: 5px; }
  .wpcf7-not-valid-tip span.far {
    margin-right: 5px; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  border: 0; }

.btn--primary {
  background-color: #ffcc24;
  color: #ffffff;
  border-radius: 30px; }
  .btn--primary:hover {
    color: #ffcc24;
    background-color: #000000; }

.btn--white {
  background-color: #ffffff;
  padding: 10px 35px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 5px; }
  .btn--white:hover {
    color: #ffcc24;
    background-color: #000000; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important; }

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 52.5%; }
  @media (min-width: 768px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 62.5%; } }

body {
  color: #5B5B5B; }

main {
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  margin-top: 90px; }
  main.is-pushed {
    transform: translateX(-290px); }
  @media (max-width: 1199.98px) {
    main {
      margin-top: 78px; } }
  @media (max-width: 767.98px) {
    main {
      margin-top: 67px; } }

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
  .backdrop.is-actived {
    opacity: 1;
    visibility: visible; }

.block-img {
  text-align: center; }
  .block-img img {
    max-width: 100%; }

.border-top {
  display: flex;
  align-items: center; }
  .border-top span {
    border-top: 1px solid #979797;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .border-top i {
    padding-left: 20px;
    font-size: 2rem;
    color: #888888; }

.block-title {
  margin-bottom: 45px; }
  .block-title .big-title + .sub-title {
    margin-top: 4.5rem; }
  .block-title .sub-title + .section-sub-title {
    margin-top: 3.5rem; }

.section-title {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.2;
  font-family: 'Big Shoulders Display', sans-serif; }
  .section-title.big-title {
    font-size: calc(3rem + (4000vw / 1920)); }
  .section-title.sub-title {
    font-weight: 500;
    font-size: calc(2rem + (3000vw / 1920));
    text-transform: uppercase; }
  @media (max-width: 1199.98px) {
    .section-title {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .section-title {
      font-size: 2.8rem; } }

.section-description {
  font-size: calc(1rem + (12vw / 1920));
  color: #333333; }

.parallax {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.parllax__img {
  opacity: 0.7;
  width: 100%;
  height: 100%;
  background-size: 97%;
  background-repeat: repeat-y;
  background-position: center 250px; }
  @media (max-width: 767.98px) {
    .parllax__img {
      display: none; } }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }

.header__wrapper {
  padding: 15px 40px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2); }
  @media (max-width: 1199.98px) {
    .header__wrapper {
      padding: 10px 25px; } }
  @media (max-width: 767.98px) {
    .header__wrapper {
      padding: 5px 10px; } }

.header__logo img {
  height: 60px; }
  @media (max-width: 1199.98px) {
    .header__logo img {
      height: 55px; } }
  @media (max-width: 767.98px) {
    .header__logo img {
      height: 50px; } }

.header__contact .btn--primary {
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
  font-size: 2.2rem;
  padding: 1.5rem 4rem; }
  .header__contact .btn--primary span.fal {
    margin-left: 10px; }
  @media (max-width: 767.98px) {
    .header__contact .btn--primary {
      padding: 1rem 3rem;
      font-size: 14px; }
      .header__contact .btn--primary span.fal {
        display: none; } }

@media (max-width: 1024.98px) {
  .header__contact {
    margin-left: auto; } }

.header__toggle {
  margin-left: 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  max-width: 30px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center; }
  .header__toggle .btn--toggle {
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .header__toggle .btn--toggle .toggle__bar {
      display: block;
      width: 20px;
      height: 2px;
      margin: 2px 0;
      background-color: #000000;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      transform-origin: 50% 50%;
      border-radius: 2px; }
    .header__toggle .btn--toggle.is-active .toggle__bar:nth-child(1) {
      transform: rotate(-45deg) translate(-2px, 2px);
      width: 14px; }
    .header__toggle .btn--toggle.is-active .toggle__bar:nth-child(2) {
      transform: rotate(45deg) translate(-2px, -2px);
      width: 14px; }
  @media (max-width: 767.98px) {
    .header__toggle {
      margin-left: 10px; } }

.header__siteMenu--hidden {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 350px;
  max-width: 100%;
  height: 100%;
  background-color: #ffcc24;
  padding: 60px 0;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
  .header__siteMenu--hidden.is-showed {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible; }
    .header__siteMenu--hidden.is-showed .header__siteMenu--close {
      opacity: 1;
      visibility: visible; }
  @media (max-width: 1199.98px) {
    .header__siteMenu--hidden {
      width: 320px; } }
  @media (max-width: 767.98px) {
    .header__siteMenu--hidden {
      width: 280px; } }

.header__siteMenu--close {
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  cursor: pointer; }
  .header__siteMenu--close span {
    color: #ffffff; }

.header__siteMenu__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch; }

.header__nav {
  margin-left: auto; }
  .header__nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex; }
    @media (max-width: 1024.98px) {
      .header__nav ul {
        flex-direction: column; } }
  .header__nav a {
    font-size: 1.7rem;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    display: inline-block;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .header__nav a:hover {
      color: #ffcc24; }
  .header__nav li {
    padding-right: 45px; }
    @media (min-width: 1025px) {
      .header__nav li.current-menu-item a {
        color: #ffcc24;
        font-weight: 500;
        border-bottom: 1px solid #ffcc24; } }
    @media (max-width: 1024.98px) {
      .header__nav li.current-menu-item a {
        border-bottom: 1px solid #000000; } }
  @media (max-width: 1024.98px) {
    .header__nav {
      padding: 0 45px;
      margin-left: 0; }
      .header__nav li {
        padding: 12px 0; }
      .header__nav a {
        font-size: 2.5rem;
        text-transform: uppercase; } }

footer {
  background-color: #ffffff; }
  footer hr {
    border: 0;
    border-top: 1px solid #dee8f2;
    margin-bottom: 50px; }

.footer-logo {
  width: 240px;
  max-width: 100%; }
  @media (max-width: 767.98px) {
    .footer-logo {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px; }
      .footer-logo img {
        max-height: 100px; } }

.footer-nav h3 {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 25px; }
  @media (max-width: 1199.98px) {
    .footer-nav h3 {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .footer-nav h3 {
      margin-bottom: 15px; } }

.footer-nav ul {
  padding-left: 0;
  list-style-type: none; }
  .footer-nav ul a {
    display: inline-block;
    padding: 5px 0;
    color: #5B5B5B;
    text-decoration: none;
    font-size: 1.8rem; }
    .footer-nav ul a .fab {
      display: inline-flex;
      justify-content: center;
      width: 18px;
      margin-right: 10px; }
    .footer-nav ul a:hover {
      color: #ffcc24; }

.index-1 {
  background-position: 1% 95%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden; }
  .index-1 .container,
  .index-1 [class*='col'] {
    position: static; }
  .index-1 .index-1__banner,
  .index-1 .index-1__container {
    z-index: 2;
    position: relative; }
  .index-1 .index-1__background {
    background-color: #ffffff;
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .index-1 .index-1__banner .block-content {
    max-width: calc(320px + calc(44000vw / 1920));
    width: 100%; }
    @media (min-width: 1025px) {
      .index-1 .index-1__banner .block-content {
        padding-bottom: 100px;
        padding-top: 100px; } }
  .index-1 .index-1__banner .block-slogan {
    font-size: calc(24px + calc(7000vw / 1920));
    font-weight: 700;
    line-height: 1.3; }
  .index-1 .index-1__banner .block-description {
    font-size: calc(14px + calc(800vw / 1920)); }
  .index-1 .index-1__banner .block-img img {
    width: 100%; }
  @media (min-width: 1025px) {
    .index-1 .index-1__banner .block-img {
      width: calc(450px + calc(45000vw / 1920));
      max-width: 100%; } }
  @media (max-width: 1024.98px) {
    .index-1 .index-1__container .block-img {
      margin-bottom: 35px; } }
  .index-1 .index-1__container .block-content p {
    font-size: 2.2rem; }

.index-2 {
  position: relative;
  background-color: #fafafa;
  background-repeat: no-repeat; }
  .index-2 .section-title--custom {
    font-size: calc(2.5rem + (2300vw / 1920));
    color: #373737; }
    @media (max-width: 767.98px) {
      .index-2 .section-title--custom {
        text-align: center;
        margin-bottom: 30px; }
        .index-2 .section-title--custom br {
          display: none; } }
  .index-2 .section-description--custom {
    font-size: 2.2rem;
    color: #5b5b5b;
    font-weight: 600; }
    @media (max-width: 767.98px) {
      .index-2 .section-description--custom {
        text-align: center; } }
  .index-2 .block-item {
    margin-bottom: 50px; }
    .index-2 .block-item .block-item__wrapper {
      border-radius: 30px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center; }
    .index-2 .block-item .item__icon {
      text-align: center;
      margin-bottom: 20px;
      -ms-flex: 0 0 80px;
      flex: 0 0 80px;
      max-width: 80px; }
      @media (max-width: 1199.98px) {
        .index-2 .block-item .item__icon {
          -ms-flex: 0 0 70px;
          flex: 0 0 70px;
          max-width: 70px; } }
      @media (max-width: 767.98px) {
        .index-2 .block-item .item__icon {
          -ms-flex: 0 0 60px;
          flex: 0 0 60px;
          max-width: 60px; } }
    .index-2 .block-item .item__text {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      padding-left: 3rem;
      padding-right: 3rem; }
      .index-2 .block-item .item__text h3 {
        font-size: 2.2rem;
        position: relative;
        margin-bottom: 15px; }
      @media (max-width: 1199.98px) {
        .index-2 .block-item .item__text {
          padding-left: 2.5rem;
          padding-right: 2.5rem; } }
      @media (max-width: 767.98px) {
        .index-2 .block-item .item__text {
          padding-left: 2rem;
          padding-right: 2rem; } }

.our-works .pd-70:nth-child(even) {
  background-color: #fafafa; }

@media (min-width: 1025px) {
  .our-works .pd-70:nth-child(odd) .our-works-article {
    flex-direction: row; }
  .our-works .pd-70:nth-child(even) .our-works-article {
    flex-direction: row-reverse; } }

.our-works .our-works-title .section-title {
  font-size: calc(3rem + (4000vw / 1920));
  color: #ee4266; }

@media (max-width: 1024.98px) {
  .our-works .our-works-title .section-title {
    text-align: center; } }

.our-works-article {
  margin-bottom: 45px; }
  .our-works-article .block-text h3 {
    font-size: 3rem;
    color: #333333; }
  .our-works-article .block-text ul {
    list-style-type: none;
    padding-left: 0; }
  .our-works-article .block-text img {
    display: inline-block;
    margin-top: 25px;
    margin-bottom: 30px; }
  .our-works-article .block-text ul,
  .our-works-article .block-text p {
    margin-bottom: 15px;
    font-size: 2.2rem;
    font-weight: 500;
    color: #5b5b5b; }
  .our-works-article .block-text ul li {
    position: relative;
    padding-left: 20px; }
    .our-works-article .block-text ul li:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0;
      top: 1.2rem;
      background-color: #5b5b5b; }
    .our-works-article .block-text ul li + li {
      margin-top: 10px; }
  .our-works-article .block-text a {
    color: #000000;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 1.8rem;
    text-decoration: underline;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .our-works-article .block-text a span.fal {
      font-size: 16px;
      margin-left: 10px;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .our-works-article .block-text a:hover {
      color: #ffcc24; }
      .our-works-article .block-text a:hover span.fal {
        margin-left: 20px; }
  @media (max-width: 1024.98px) {
    .our-works-article .block-img {
      margin-bottom: 30px; }
      .our-works-article .block-img img {
        max-height: 375px; } }
  @media (max-width: 1024.98px) {
    .our-works-article .block-text {
      padding: 20px;
      background-color: rgba(255, 204, 36, 0.5); } }
  @media (min-width: 1025px) {
    .our-works-article {
      margin-bottom: 0; }
      .our-works-article .block-text {
        padding: 0 50px; }
      .our-works-article:nth-child(even) .col-lg-4 {
        order: 3; }
      .our-works-article:nth-child(even) .col-lg-1 {
        order: 2; }
      .our-works-article:nth-child(even) .col-lg-7 {
        order: 1; } }

.what-we-do .block-title p {
  font-size: 2.2rem; }

.what-we-do-1 .section-title {
  color: #f38a67; }

.what-we-do-1 .grid-container {
  display: flex;
  flex-wrap: wrap; }
  .what-we-do-1 .grid-container .item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -ms-flex: 0 0 calc(100% / 8);
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8); }
    .what-we-do-1 .grid-container .item .text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      padding: 15px; }
      .what-we-do-1 .grid-container .item .text p {
        font-size: 2.2rem;
        font-weight: 500;
        color: #ffffff; }
    .what-we-do-1 .grid-container .item .img {
      width: 100%;
      height: 100%;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
      .what-we-do-1 .grid-container .item .img img {
        object-fit: cover;
        transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        width: 100%;
        height: 100%; }
    @media (min-width: 1025px) {
      .what-we-do-1 .grid-container .item:hover .img {
        background-color: black; }
        .what-we-do-1 .grid-container .item:hover .img img {
          opacity: 0.5; } }
    .what-we-do-1 .grid-container .item:nth-child(13), .what-we-do-1 .grid-container .item:nth-child(14), .what-we-do-1 .grid-container .item:nth-child(15) {
      -ms-flex: 0 0 calc(100% / 4);
      flex: 0 0 calc(100% / 4);
      max-width: calc(100% / 4); }
  @media (max-width: 767.98px) {
    .what-we-do-1 .grid-container {
      margin: 0 -5px; }
      .what-we-do-1 .grid-container .item {
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%; }
        .what-we-do-1 .grid-container .item:nth-child(13), .what-we-do-1 .grid-container .item:nth-child(14), .what-we-do-1 .grid-container .item:nth-child(15) {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%; } }

.what-we-do-2 .section-title {
  color: #ffcc24; }

.what-we-do-2 .highlight-project {
  display: block; }
  .what-we-do-2 .highlight-project .img {
    padding-top: 150%;
    display: block;
    position: relative; }
    .what-we-do-2 .highlight-project .img img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.what-we-do-2 .highlight-project-wrapper .col-lg-3 {
  margin-bottom: 35px; }
  @media (min-width: 1025px) {
    .what-we-do-2 .highlight-project-wrapper .col-lg-3:nth-child(2) {
      padding-top: 60px; }
    .what-we-do-2 .highlight-project-wrapper .col-lg-3:nth-child(4) {
      padding-top: 60px; } }

.what-we-do-2 .what-we-do-2-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px; }
  .what-we-do-2 .what-we-do-2-item a {
    display: block;
    width: 100%;
    height: 100%; }
    .what-we-do-2 .what-we-do-2-item a img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .what-we-do-2 .what-we-do-2-item:nth-child(1), .what-we-do-2 .what-we-do-2-item:nth-child(3) {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%; }
  .what-we-do-2 .what-we-do-2-item:nth-child(2) {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%; }
  @media (max-width: 767.98px) {
    .what-we-do-2 .what-we-do-2-item:nth-child(1), .what-we-do-2 .what-we-do-2-item:nth-child(3) {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      order: 2;
      margin-top: 20px; }
    .what-we-do-2 .what-we-do-2-item:nth-child(2) {
      order: 1;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }

.what-we-do-2 .pd-70:nth-child(2) {
  background-color: #fafafa; }

.what-we-do-3 .section-title.big-title {
  color: #ffcc24; }

.what-we-do-3 .section-title.sub-title {
  color: #77cbd2; }

.what-we-do-3 .col-6.col-lg-3 {
  margin-bottom: 35px; }

.what-we-do-4 .section-title.sub-title {
  color: #f38a67; }

.what-we-do-4 .block-title p {
  color: #5b5b5b;
  font-size: 1.8rem;
  max-width: 500px; }

.what-we-do-4 .celeb-img:not(.big-img) {
  padding-top: 121.05263%;
  display: block;
  position: relative; }
  .what-we-do-4 .celeb-img:not(.big-img) img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.what-we-do-4 .celeb-img.big-img {
  margin-top: 20px;
  padding-top: 42.56881%;
  display: block;
  position: relative; }
  .what-we-do-4 .celeb-img.big-img img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.what-we-do-5 .section-title.sub-title {
  color: #253083; }

.what-we-do-5 .col-6.col-lg-3 {
  margin-bottom: 35px; }

.what-we-do-6 .section-title.sub-title {
  color: #3bc8b4; }

.what-we-do-6 .col-6.col-lg-4 {
  margin-bottom: 35px; }

.what-we-do-7 .section-title.sub-title {
  color: #f95c0e;
  font-family: 'Circular Std', sans-serif; }

.what-we-do-7 .col-6.col-lg-4 {
  margin-bottom: 35px; }

.what-we-do-7 .block-title {
  display: flex;
  align-items: center; }
  .what-we-do-7 .block-title .section-img {
    margin-right: 30px;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    max-width: 120px; }
  .what-we-do-7 .block-title .section-title {
    margin-bottom: 0;
    padding-bottom: 0; }

.project-item {
  height: 100%; }
  .project-item .project-img {
    padding-top: 60.90226%;
    display: block;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    overflow: hidden; }
    .project-item .project-img img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .project-item .project-link {
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column; }
  .project-item .project-info {
    padding-top: 2rem;
    color: #333333;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem; }
    .project-item .project-info h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
      text-transform: capitalize; }
    .project-item .project-info p {
      font-size: 1.8rem;
      margin-bottom: auto; }
    .project-item .project-info h4 {
      margin-top: 1rem; }
    .project-item .project-info i {
      margin-top: 1rem;
      display: block;
      font-size: 1.6rem; }

.fancybox-is-open .fancybox-bg {
  opacity: 0.35; }

.fancybox-content.celeb-dialog {
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  max-height: 100%;
  overflow-y: auto; }
  .fancybox-content.celeb-dialog .fancybox-close-small {
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .fancybox-content.celeb-dialog .fancybox-close-small:hover {
      background: rgba(0, 0, 0, 0.1); }
  .fancybox-content.celeb-dialog .celeb-dialog-wrapper {
    display: flex;
    margin: 0 -10px; }
    .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-img {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
      padding: 0 10px; }
      .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-img .img-wrapper img {
        width: 100%; }
    .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content {
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      max-width: 60%;
      padding: 0 10px;
      color: #000000; }
      .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content h4 {
        font-size: 2.2rem; }
      .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content p,
      .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content ul {
        font-size: 1.6rem;
        margin-bottom: 7px; }
  @media (max-width: 1024.98px) {
    .fancybox-content.celeb-dialog {
      max-width: 450px; }
      .fancybox-content.celeb-dialog .celeb-dialog-wrapper {
        flex-wrap: wrap; }
        .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-img,
        .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%; }
        .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-img {
          text-align: center; }
          .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-img .img-wrapper img {
            max-height: 200px;
            width: auto; }
        .fancybox-content.celeb-dialog .celeb-dialog-wrapper .dialog-content {
          margin-top: 15px; } }

.contact-1 .btn--primary {
  height: 64px;
  border-radius: 35px;
  font-size: 2.2rem;
  color: #ffffff;
  padding: 0 60px;
  font-weight: 500; }

.contact-1 .form-group a {
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
  .contact-1 .form-group a:hover {
    color: #ffcc24; }
