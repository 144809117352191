.contact-1 {
	.btn--primary {
		height: 64px;
		border-radius: 35px;
		font-size: 2.2rem;
		color: map-get($color, white);
		padding: 0 60px;
		font-weight: 500;
	}
	.form-group {
		a {
			@extend .form-group__label;
			font-weight: 400;
			text-decoration: none;
			display: inline-block;
			transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			&:hover {
				color: map-get($color, main);
			}
		}
	}
}
