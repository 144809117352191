* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility !important;
}

html {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 52.5%;

	@media (min-width: 768px) {
		font-size: 57.5%;
	}

	@media (min-width: 1200px) {
		font-size: 62.5%;
	}
}

body {
	color: map-get($color, text);
}

main {
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	margin-top: 90px;

	&.is-pushed {
		transform: translateX(-290px);
	}

	@media (max-width: 1199.98px) {
		margin-top: 78px;
	}

	@media (max-width: 767.98px) {
		margin-top: 67px;
	}
}

.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(black, 0.85);
	opacity: 0;
	visibility: hidden;
	z-index: 999;
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

	&.is-actived {
		opacity: 1;
		visibility: visible;
	}
}

.block-img {
	text-align: center;
	img {
		max-width: 100%;
	}
}

.border-top {
	display: flex;
	align-items: center;
	span {
		border-top: 1px solid #979797;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	i {
		padding-left: 20px;
		font-size: 2rem;
		color: #888888;
	}
}

.block-title {
	margin-bottom: 45px;
	.big-title + .sub-title {
		margin-top: 4.5rem;
	}
	.sub-title + .section-sub-title {
		margin-top: 3.5rem;
	}
}

.section-title {
	font-size: 3.2rem;
	font-weight: 900;
	line-height: 1.2;
	font-family: 'Big Shoulders Display', sans-serif;
	&.big-title {
		font-size: calc(3rem + (4000vw / 1920));
	}
	&.sub-title {
		font-weight: 500;
		font-size: calc(2rem + (3000vw / 1920));
		text-transform: uppercase;
	}
	@media (max-width: 1199.98px) {
		font-size: 3rem;
	}
	@media (max-width: 767.98px) {
		font-size: 2.8rem;
	}
}
.section-description {
	font-size: calc(1rem + (12vw / 1920));
	color: #333333;
}
.parallax {
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.parllax__img {
	opacity: 0.7;
	width: 100%;
	height: 100%;
	background-size: 97%;
	background-repeat: repeat-y;
	background-position: center 250px;
	@media (max-width: 767.98px) {
		display: none;
	}
}
