.form-group {
	display: block;
	width: 100%;
	margin-bottom: 20px;
}
.form-control {
	width: 100%;
	display: block;
	border: 1px solid map-get($color, white);
	border-radius: 5px;
	padding: 10px 15px;
	box-shadow: 0 0 10px rgba(map-get($color, black), 0.2);
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	font-size: 16px;
	height: 50px;
	&:active,
	&:focus {
		outline: none;
		box-shadow: 5px 5px 10px rgba(map-get($color, black), 0.3);
	}
}
.form-control--textarea {
	height: 140px;
}
.form-group__label {
	display: block;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 2.2rem;
	color: #2c3241;
}
.wpcf7-not-valid-tip {
	color: red;
	margin-top: 5px;
	text-align: right;
	font-weight: 500;
	display: block;
	&:before {
		content: '\f06a';
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 400;
		margin-right: 5px;
	}
	span.far {
		margin-right: 5px;
	}
}
