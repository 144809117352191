@mixin img($ratio) {
	padding-top: (1 / $ratio) * 100%;
	display: block;
	position: relative;
	img {
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
