.index-1 {
	background-position: 1% 95%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
	.container,
	[class*='col'] {
		position: static;
	}
	.index-1__banner,
	.index-1__container {
		z-index: 2;
		position: relative;
	}
	.index-1__background {
		background-color: map-get($color, white);
		z-index: 1;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.index-1__banner {
		.block-content {
			max-width: calc(320px + calc(44000vw / 1920));
			width: 100%;
			@media (min-width: 1025px) {
				padding-bottom: 100px;
				padding-top: 100px;
			}
		}
		.block-slogan {
			font-size: calc(24px + calc(7000vw / 1920));
			font-weight: 700;
			line-height: 1.3;
		}
		.block-description {
			font-size: calc(14px + calc(800vw / 1920));
		}
		.block-img {
			img {
				width: 100%;
			}
			@media (min-width: 1025px) {
				width: calc(450px + calc(45000vw / 1920));
				max-width: 100%;
			}
		}
	}
	.index-1__container {
		.block-img {
			@media (max-width: 1024.98px) {
				margin-bottom: 35px;
			}
		}
		.block-content {
			p {
				font-size: 2.2rem;
			}
		}
	}
}

.index-2 {
	position: relative;
	background-color: #fafafa;
	background-repeat: no-repeat;
	.section-title--custom {
		font-size: calc(2.5rem + (2300vw / 1920));
		color: #373737;
		@media (max-width: 767.98px) {
			text-align: center;
			margin-bottom: 30px;
			br {
				display: none;
			}
		}
	}
	.section-description--custom {
		font-size: 2.2rem;
		color: #5b5b5b;
		font-weight: 600;
		@media (max-width: 767.98px) {
			text-align: center;
		}
	}
	.block-item {
		margin-bottom: 50px;
		.block-item__wrapper {
			border-radius: 30px;
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
		}
		.item__icon {
			text-align: center;
			margin-bottom: 20px;
			-ms-flex: 0 0 80px;
			flex: 0 0 80px;
			max-width: 80px;
			@media (max-width: 1199.98px) {
				-ms-flex: 0 0 70px;
				flex: 0 0 70px;
				max-width: 70px;
			}
			@media (max-width: 767.98px) {
				-ms-flex: 0 0 60px;
				flex: 0 0 60px;
				max-width: 60px;
			}
		}
		.item__text {
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			-ms-flex-positive: 1;
			flex-grow: 1;
			max-width: 100%;
			padding-left: 3rem;
			padding-right: 3rem;
			h3 {
				font-size: 2.2rem;
				position: relative;
				margin-bottom: 15px;
			}
			@media (max-width: 1199.98px) {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
			}
			@media (max-width: 767.98px) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}
}

// .index-3 {
// 	@extend .index-2;
// 	padding-top: 70px;
// 	&:before {
// 		content: none;
// 	}
// 	background-color: #fae9b2;
// 	background-image: url('/images/index-1-bg-3.png');
// 	background-repeat: no-repeat;
// 	background-position: 0 0;
// 	background-size: 100%;
// 	// .block-img {
// 	// 	animation: UpdownMoving 2s infinite alternate;
// 	// }
// 	@media (max-width: 1199.98px) {
// 		background-size: 120%;
// 	}
// 	@media (max-width: 1024.98px) {
// 		.block-img {
// 			padding: 30px;
// 			max-width: 400px;
// 			margin-left: auto;
// 			margin-right: auto;
// 		}
// 		.col-lg-6 {
// 			order: 1;
// 		}
// 		.col-lg-1 {
// 			order: 2;
// 		}
// 		.col-lg-5 {
// 			order: 3;
// 		}
// 	}
// 	@media (max-width: 767.98px) {
// 		background-size: 150%;
// 	}
// }

// .index-4 {
// 	.section-title {
// 		color: map-get($color, white);
// 		margin-bottom: 50px;
// 	}
// 	.block-item {
// 		padding-top: 60px;
// 		padding: 60px 35px 0;
// 		height: 100%;
// 		max-width: 400px;
// 		margin-left: auto;
// 		margin-right: auto;
// 		.block-item__wrapper {
// 			border-radius: 30px;
// 			box-shadow: 0 6px 16px rgba(map-get($color, black), 0.1);
// 			padding: 65px 25px 35px;
// 			position: relative;
// 			background-color: map-get($color, white);
// 			height: 100%;
// 		}
// 		.item__icon {
// 			width: 90px;
// 			height: 90px;
// 			box-shadow: 0 0px 6px rgba(map-get($color, black), 0.1);
// 			background-color: map-get($color, white);
// 			border-radius: 50%;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			position: absolute;
// 			left: 50%;
// 			top: -45px;
// 			transform: translateX(-50%);
// 			color: map-get($color, main);

// 			span.fas {
// 				font-size: 40px;
// 			}
// 		}
// 		.item__text {
// 			h3 {
// 				font-size: 2rem;
// 				text-transform: uppercase;
// 				position: relative;
// 				padding-bottom: 15px;
// 				margin-bottom: 15px;
// 				&:before {
// 					height: 1px;
// 					width: 120px;
// 					position: absolute;
// 					left: 50%;
// 					bottom: 0;
// 					transform: translateX(-50%);
// 					content: '';
// 					background-color: map-get($color, black);
// 				}
// 			}
// 			p {
// 				line-height: 1.5;
// 				font-size: 1.7rem;
// 			}
// 		}
// 		@media (max-width: 1199.98px) {
// 			padding: 60px 10px 0;
// 		}
// 		@media (max-width: 1024.98px) {
// 			padding-bottom: 40px;
// 		}
// 		@media (max-width: 767.98px) {
// 			padding: 40px 10px 40px;
// 		}
// 	}
// }

// .index-5 {
// 	background-image: url('/images/index-1-bg-4.png');
// 	background-repeat: no-repeat;
// 	background-position: 0 100%;
// 	background-size: 100%;
// 	.block-text {
// 		margin-top: 4.5rem;
// 		p {
// 			font-size: 1.7rem;
// 		}
// 	}
// 	@media (max-width: 1024.98px) {
// 		.block-img {
// 			padding: 30px;
// 			max-width: 400px;
// 			margin-left: auto;
// 			margin-right: auto;
// 		}
// 	}
// }
