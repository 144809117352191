.blur-up {
	-webkit-filter: blur(5px);
	filter: blur(5px);
	transition: filter 400ms, -webkit-filter 400ms;
	&.lazyloaded {
		-webkit-filter: blur(0);
		filter: blur(0);
	}
}

.w-100 {
	width: 100%;
}
.h-100 {
	height: 100%;
}
.pt-70 {
	padding-top: 100px;
	@media (max-width: 1199.98px) {
		padding-top: 70px;
	}
	@media (max-width: 767.98px) {
		padding-top: 40px;
	}
}
.pb-70 {
	padding-bottom: 100px;
	@media (max-width: 1199.98px) {
		padding-bottom: 70px;
	}
	@media (max-width: 767.98px) {
		padding-bottom: 40px;
	}
}
.pd-70 {
	padding-top: 100px;
	padding-bottom: 100px;
	@media (max-width: 1199.98px) {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	@media (max-width: 767.98px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
.mobile {
	@media (min-width: 1025px) {
		display: none !important;
	}
}
.desktop {
	@media (max-width: 1024.98px) {
		display: none !important;
	}
}
.d-none {
	display: none;
}
.ov-h {
	overflow: hidden;
}
.jc-c {
	justify-content: center;
}
.jc-sb {
	justify-content: space-between;
}
.jc-fe {
	justify-content: flex-end;
}
.ai-c {
	align-items: center;
}
.as-fs {
	align-self: flex-start;
}
.ta-c {
	text-align: center;
}
.ta-r {
	text-align: right;
}
.ta-l {
	text-align: left;
}
.ta-j {
	text-align: justify;
}
.tal-c {
	text-align-last: center;
}
.tt-u {
	text-transform: uppercase;
}
.fs-i {
	font-style: italic;
}
