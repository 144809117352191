@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Black'), local('BigShouldersDisplay-Black'),
			url('../fonts/BigShouldersDisplay-Black.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display ExtraBold'), local('BigShouldersDisplay-ExtraBold'),
			url('../fonts/BigShouldersDisplay-ExtraBold.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Bold'), local('BigShouldersDisplay-Bold'),
			url('../fonts/BigShouldersDisplay-Bold.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Medium'), local('BigShouldersDisplay-Medium'),
			url('../fonts/BigShouldersDisplay-Medium.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Light'), local('BigShouldersDisplay-Light'),
			url('../fonts/BigShouldersDisplay-Light.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Regular'), local('BigShouldersDisplay-Regular'),
			url('../fonts/BigShouldersDisplay-Regular.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display SemiBold'), local('BigShouldersDisplay-SemiBold'),
			url('../fonts/BigShouldersDisplay-SemiBold.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Big Shoulders Display';
	src: local('Big Shoulders Display Thin'), local('BigShouldersDisplay-Thin'),
			url('../fonts/BigShouldersDisplay-Thin.woff2') format('woff2'),
			url('../fonts/BigShouldersDisplay-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Black'), local('CircularStd-Black'),
			url('../fonts/CircularStd-Black.woff2') format('woff2'),
			url('../fonts/CircularStd-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Black Italic'), local('CircularStd-BlackItalic'),
			url('../fonts/CircularStd-BlackItalic.woff2') format('woff2'),
			url('../fonts/CircularStd-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'),
			url('../fonts/CircularStd-BoldItalic.woff2') format('woff2'),
			url('../fonts/CircularStd-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Bold'), local('CircularStd-Bold'),
			url('../fonts/CircularStd-Bold.woff2') format('woff2'),
			url('../fonts/CircularStd-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std Book';
	src: local('Circular Std Book'), local('CircularStd-Book'),
			url('../fonts/CircularStd-Book.woff2') format('woff2'),
			url('../fonts/CircularStd-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std Book';
	src: local('Circular Std Book Italic'), local('CircularStd-BookItalic'),
			url('../fonts/CircularStd-BookItalic.woff2') format('woff2'),
			url('../fonts/CircularStd-BookItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Medium'), local('CircularStd-Medium'),
			url('../fonts/CircularStd-Medium.woff2') format('woff2'),
			url('../fonts/CircularStd-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Circular Std';
	src: local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'),
			url('../fonts/CircularStd-MediumItalic.woff2') format('woff2'),
			url('../fonts/CircularStd-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque Black'), local('DarkerGrotesque-Black'),
			url('../fonts/DarkerGrotesque-Black.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque Bold'), local('DarkerGrotesque-Bold'),
			url('../fonts/DarkerGrotesque-Bold.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque ExtraBold'), local('DarkerGrotesque-ExtraBold'),
			url('../fonts/DarkerGrotesque-ExtraBold.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque Light'), local('DarkerGrotesque-Light'),
			url('../fonts/DarkerGrotesque-Light.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque Medium'), local('DarkerGrotesque-Medium'),
			url('../fonts/DarkerGrotesque-Medium.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque Regular'), local('DarkerGrotesque-Regular'),
			url('../fonts/DarkerGrotesque-Regular.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Darker Grotesque';
	src: local('Darker Grotesque SemiBold'), local('DarkerGrotesque-SemiBold'),
			url('../fonts/DarkerGrotesque-SemiBold.woff2') format('woff2'),
			url('../fonts/DarkerGrotesque-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}



@font-face {
	font-family: 'Drotesk No5';
	src: local('Drotesk No5 Medium'), local('DroteskNo5-Medium'),
		url('../fonts/DroteskNo5-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Drotesk No5';
	src: local('Drotesk No5 Regular'), local('DroteskNo5-Regular'),
		url('../fonts/DroteskNo5-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	src: url('../fonts/fa-light-300.woff2') format('woff2'),
		url('../fonts/fa-light-300.woff') format('woff');
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: normal;
	font-display: auto;
	src: url('../fonts/fa-brands-400.woff2') format('woff2'),
		url('../fonts/fa-brands-400.woff') format('woff');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url('../fonts/fa-regular-400.woff2') format('woff2'),
		url('../fonts/fa-regular-400.woff') format('woff');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url('../fonts/fa-solid-900.woff2') format('woff2'),
		url('../fonts/fa-solid-900.woff') format('woff');
}

.fab {
	font-family: 'Font Awesome 5 Brands';
}
.fal {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}
.far {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}
.fa,
.fad,
.fas {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.fa-external-link-alt:before {
	content: '\f35d';
}
.fa-chevron-up:before {
	content: '\f077';
}
.fa-chevron-left:before {
	content: '\f053';
}
.fa-chevron-right:before {
	content: '\f054';
}
.fa-bars:before {
	content: '\f0c9';
}
.fa-chevron-down:before {
	content: '\f078';
}
.fa-home-lg-alt:before {
	content: '\f80c';
}
.fa-shopping-cart:before {
	content: '\f07a';
}
.fa-search:before {
	content: '\f002';
}
.fa-calendar-alt:before {
	content: '\f073';
}
.fa-google::before {
	content: '\f1a0';
}
.fa-facebook-f::before {
	content: '\f39e';
}
.fa-facebook-square::before {
	content: '\f082';
}
.fa-twitter::before {
	content: '\f099';
}
.fa-youtube:before {
	content: '\f167';
}
.fa-instagram::before {
	content: '\f16d';
}
.fa-tripadvisor::before {
	content: '\f262';
}
.fa-map-marker-alt:before {
	content: '\f3c5';
}
.fa-times:before {
	content: '\f00d';
}
.fa-ellipsis-h:before {
	content: '\f141';
}
.fa-check:before {
	content: '\f00c';
}
.fa-redo-alt:before {
	content: '\f2f9';
}
.fa-star:before {
	content: '\f005';
}
.fa-thumbs-up::before {
	content: '\f164';
}
.fa-plus-square:before {
	content: '\f0fe';
}

.fa-pinterest-p::before {
	content: '\f231';
}
.fa-linkedin-in::before {
	content: '\f0e1';
}
.fa-edit:before {
	content: '\f044';
}
.fa-gift:before {
	content: '\f06b';
}
.fa-comments-alt:before {
	content: '\f4b6';
}
.fa-filter:before {
	content: '\f0b0';
}
.fa-pig:before {
	content: '\f706';
}
.fa-comment-alt-edit:before {
	content: '\f4a4';
}
.fa-eye:before {
	content: '\f06e';
}
.fa-gem:before {
	content: '\f3a5';
}
.fa-bullseye:before {
	content: '\f140';
}
.fa-exclamation-circle:before {
	content: '\f06a';
}
.fa-paper-plane:before {
	content: '\f1d8';
}
