footer {
	background-color: map-get($color, white);
	hr {
		border: 0;
		border-top: 1px solid #dee8f2;
		margin-bottom: 50px;
	}
}
.footer-logo {
	width: 240px;
	max-width: 100%;
	@media (max-width: 767.98px) {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		img {
			max-height: 100px;
		}
	}
}
.footer-nav {
	h3 {
		font-size: 2.5rem;
		font-weight: 500;
		text-transform: capitalize;
		margin-bottom: 25px;
		@media (max-width: 1199.98px) {
			margin-bottom: 20px;
		}
		@media (max-width: 767.98px) {
			margin-bottom: 15px;
		}
	}
	ul {
		padding-left: 0;
		list-style-type: none;
		a {
			display: inline-block;
			padding: 5px 0;
			color: map-get($color, text);
			text-decoration: none;
			font-size: 1.8rem;
			.fab {
				display: inline-flex;
				justify-content: center;
				width: 18px;
				margin-right: 10px;
			}
			&:hover {
				color: map-get($color, main);
			}
		}
	}
}
